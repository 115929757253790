<template>
  <el-dialog
    :title="`${isEdit ? '编辑' : '新增'}学校信息`"
    :visible.sync="visible"
    custom-class="ql-dialog schoolDialog"
    width="594px"
    :close-on-click-modal="false"
    @close="resetForm"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="学校编号" prop="schoolKey" size="small">
        <el-input v-model.trim="formData.schoolKey" placeholder="请输入学校编号" :disabled="isEdit"></el-input>
      </el-form-item>
      <el-form-item label="学校名称" prop="schoolName" size="small">
        <el-input v-model.trim="formData.schoolName" placeholder="请输入学校名称"></el-input>
      </el-form-item>
      <el-form-item label="学校学段" prop="schoolType" size="small">
        <el-select v-model="formData.schoolType" style="width: 100%">
          <el-option
            v-for="item in popupData.schoolTypeList"
            :key="item.typeId"
            :label="item.typeName"
            :value="item.typeId"
            :title="item.typeName"
            class="select-option"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="服务器类型" prop="architectId" size="small">
        <el-select v-model="formData.architectId" style="width: 100%">
          <el-option
            v-for="item in popupData.architectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            :title="item.name"
            class="select-option"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属教育局" size="small">
        <el-select v-model="formData.educationKey" style="width: 100%">
          <el-option
            v-for="item in popupData.educationList"
            :key="item.educationKey"
            :label="item.educationName"
            :value="item.educationKey"
            :title="item.educationName"
            class="select-option"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属地区" prop="areaCode" size="small">
        <areaSelect
          ref="areaSelect"
          class="form-width_md"
          :placeholder="formData.areaName || '请输入所属地区'"
          :defaultValue="formData.defaultCode"
          @getCheckNodesData="getAreaInfo"
          style="width: 100%"
        />
      </el-form-item>
      <el-form-item label="有效期" prop="validateTime" size="small"
        ><el-date-picker
          v-model="formData.validateTime"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
          style="width: 100%"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleCancel" size="small">取消</el-button>
      <el-button type="primary" @click="handleAdd" size="small">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { Button, Dialog, Form, FormItem, Input, Select, Option, DatePicker } from 'element-ui';
import areaSelect from '@/components/areaSelect';
import { saveRegisterSchool } from '@/api/back-stage';

export default {
  name: 'SchoolDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    dialogData: Object,
    popupData: Object,
  },
  data() {
    const validateSchoolKey = (rule, value, callback) => {
      if (!value || !value.trim()) {
        callback(new Error('学校编号不能为空'));
        return;
      }
      callback();
    };
    const validateSchoolName = (rule, value, callback) => {
      if (!value || !value.trim()) {
        callback(new Error('学校名称不能为空'));
        return;
      }
      callback();
    };
    return {
      rules: {
        schoolKey: [
          {
            validator: validateSchoolKey,
            trigger: 'blur',
          },
        ],
        schoolName: [
          {
            validator: validateSchoolName,
            trigger: 'blur',
          },
        ],
        schoolType: [
          {
            required: true,
            message: '学段不能为空',
            trigger: 'blur',
          },
        ],
        architectId: [
          {
            required: true,
            message: '服务器类型不能为空',
            trigger: 'blur',
          },
        ],
        // educationKey: [
        //   {
        //     required: true,
        //     message: '请填写所属教育局',
        //     trigger: 'blur',
        //   },
        // ],
        areaCode: [
          {
            required: true,
            message: '省份不能为空',
            trigger: 'change',
          },
        ],
        validateTime: [
          {
            required: true,
            message: '有效期不能为空',
            trigger: 'change',
          },
        ],
      },
      formData: {},
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.formData = this.dialogData;
      }
    },
  },
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    areaSelect,
  },
  methods: {
    handleAdd() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveRegisterSchool({
            id: this.isEdit ? this.formData.id : undefined,
            schoolKey: this.formData.schoolKey,
            schoolName: this.formData.schoolName,
            schoolType: this.formData.schoolType,
            architectId: this.formData.architectId,
            serverName: this.formData.educationKey,
            areaCode: this.formData.areaCode,
            areaPath: this.formData.areaPath,
            areaName: this.formData.areaName,
            validateTime: this.formData.validateTime,
          }).then(
            (res) => {
              if (res && res.result === 0) {
                this.$emit('success', this.formData);
              } else {
                this.$message.error(res.msg);
              }
            },
            (err) => {
              console.log(err, 'error submit!!');
            }
          );
        }
        return false;
      });
    },

    handleCancel() {
      this.visible = false;
      this.$refs.areaSelect.resetData();
      this.$refs.form.clearValidate();
    },

    // 表单重置
    resetForm() {
      this.$refs.areaSelect.resetData();
      this.$refs.form.resetFields();
      for (let i in this.form) {
        this.form[i] = '';
      }
    },

    // 获取地区信息
    getAreaInfo(area) {
      console.log(area);
      let areaName = area.map((item) => item.areaName).join('/');
      this.formData.areaName = `/${areaName}/`;
      this.formData.areaPath = area[area.length - 1].path;
      this.formData.areaCode = area[area.length - 1].areaCode;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-option {
  max-width: 396px;
}
</style>
